import merge from "lodash/merge";
import { createTheme as createMuiTheme, responsiveFontSizes } from "@mui/material/styles";
import { esES, enUS, ptPT, Localization } from "@mui/material/locale";
import type { Theme, ThemeOptions } from "@mui/material";
import { ThemeName } from "@/types/constants";
import { lightShadows, darkShadows } from "./shadows";
import { Environment, getEnvironment } from "@/config";
import { Lang } from "@/translations/AvailableLanguages";

interface ThemeConfig {
  responsiveFontSizes?: boolean;
  theme?: string;
}

const ENV_PRIMARY_COLOR = {
  [Environment.PRODUCTION]: "#5664d2",
  [Environment.STAGING]: "#56d28d",
  [Environment.DEVELOPMENT]: "#b59f38",
};

function mapEnvToPrimaryColor(): string {
  const environment: Environment = getEnvironment();
  const color: string = ENV_PRIMARY_COLOR[environment];

  if (color) {
    return color;
  }
  throw new Error(`Cant map environment ${environment} to color`);
}

const baseOptions: ThemeOptions = {
  direction: "ltr",
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "translucent" },
          style: {
            border: "1px solid currentColor",
          },
        },
        {
          props: { variant: "translucent", color: "default" },
          style: {
            color: "#6b778c",
            border: "1px solid rgba(145, 158, 171, 0.9)",
            backgroundColor: "rgba(66, 82, 110, 0.1)",
          },
        },
        {
          props: { variant: "translucent", color: "success" },
          style: {
            color: "#4caf50",
            backgroundColor: "rgba(76, 175, 80, 0.2)",
          },
        },
        {
          props: { variant: "translucent", color: "error" },
          style: {
            color: "#f44336",
            backgroundColor: "#f4433620",
          },
        },
        {
          props: { variant: "translucent", color: "info" },
          style: {
            color: "#2196f3",
            backgroundColor: "#2196f320",
          },
        },
        {
          props: { variant: "translucent", color: "warning" },
          style: {
            color: "#ff9800",
            backgroundColor: "#ff980020",
          },
        },
        {
          props: { variant: "translucent", color: "primary" },
          style: {
            color: mapEnvToPrimaryColor(),
            backgroundColor: mapEnvToPrimaryColor() + "20",
          },
        },
      ],
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
    },
    overline: {
      fontWeight: 600,
    },
  },
};

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    translucent: true;
  }
}

const themesOptions: Record<string, ThemeOptions> = {
  [ThemeName.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#f4f5f7",
        paper: "#ffffff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: mapEnvToPrimaryColor(),
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#172b4d",
        secondary: "#6b778c",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: lightShadows,
  },
  [ThemeName.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
    },
    palette: {
      background: {
        default: "#171c24",
        paper: "#222b36",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#688eff",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#ffffff",
        secondary: "#919eab",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: darkShadows,
  },
};

const muiLocaleMap: Record<Lang, Localization> = {
  es: esES,
  en: enUS,
  pt: ptPT,
};

const getMuiLocale = (lang: Lang): Localization => {
  return muiLocaleMap[lang] ?? muiLocaleMap.es;
};

export const createTheme = (config: ThemeConfig = {}, language: Lang): Theme => {
  let themeOptions = themesOptions[config.theme as ThemeName];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[ThemeName.LIGHT];
  }

  let theme = createMuiTheme(merge({}, baseOptions, themeOptions), getMuiLocale(language));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
