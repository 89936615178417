export enum PermissionCode {
  // User permissions
  USER_IMPERSONATE = "user:impersonate",
  USER_EDIT = "user:edit",
  USER_DELETE = "user:delete",
  USER_CREATE = "user:create",
  USER_RESET_PASSWORD = "user:resetPassword",

  // Organization permissions
  ORGANIZATION_EDIT = "organization:edit",

  // Contacts
  CONTACT_OFFBOARDING_LIST = "contact.offboarding:list",

  // Front sections
  FRONT_SECTION_TEAMS_VIEW = "front.section.teams:view",
  FRONT_SECTION_REPORTING_VIEW = "front.section.reporting:view",
  FRONT_SECTION_MANAGER_MY_TEAM_VIEW = "front.section.manager.myTeam:view",
  FRONT_SECTION_MANAGER_TEAM_COMPARISON_VIEW = "front.section.manager.teamComparison:view",

  // File
  FILE_INTEGRATION_SAMPLE = "file.integration:sample",

  // Boards
  BOARD_EDIT = "board:edit",

  // Roles
  ROLE_LIST = "role:list",
}
