import "react-perfect-scrollbar/dist/css/styles.css";
import "nprogress/nprogress.css";
import "@/sentry";
import { StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { createRoot } from "react-dom/client";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App pca={msalInstance} />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      </HelmetProvider>
    </StrictMode>
  );
});

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
