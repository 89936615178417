import { LoginServerSideErrors } from "@/contexts/AuthContext";
import { originalOrgDashboardAxios as axios, dashboardAxios, axiosWithoutOrg, isAxiosError } from "@/lib/axios";
import { Role } from "@/types/user";
import { ListResult } from "@alanszp/core";
import { JWTUser, Permission } from "@alanszp/jwt";
import { CredentialResponse } from "@react-oauth/google";

export async function forgotPassword(email: string): Promise<void> {
  await axiosWithoutOrg.post<void>(`/auth/forgot-password`, { email });
}

export async function getCurrentJwtUser(): Promise<JWTUser | null> {
  try {
    const plainJwtUser = await axiosWithoutOrg.get<JWTUser>("/auth/credentials", {
      withCredentials: true,
    });
    return new JWTUser(plainJwtUser.data);
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response?.status === 401) return null;
    throw error;
  }
}

export async function getPermissions(page: number, pageSize: number): Promise<ListResult<Permission>> {
  const response = await axiosWithoutOrg.get<ListResult<Permission>>("/auth/permissions", {
    params: { page, pageSize },
  });
  return response.data;
}

export async function sessionLogout(): Promise<void> {
  try {
    await axiosWithoutOrg.post("/auth/logout");
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response?.status === 401) return;
    throw error;
  }
}

export async function doImpersonate(organizationReference: string, userId: string): Promise<void> {
  try {
    await axiosWithoutOrg.post(`/${organizationReference}/v1/users/${userId}/impersonate`);
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response?.status === 401) return;
    throw error;
  }
}

export async function stopImpersonating(): Promise<void> {
  try {
    await axiosWithoutOrg.post("/auth/logout/impersonation");
  } catch (error: unknown) {
    if (isAxiosError(error) && error.response?.status === 401) return;
    throw error;
  }
}

type MfaGenerateSecret = {
  secret: string;
  url: string;
  email: string;
};

export async function totpGenerateSecret(organizationReference: string): Promise<MfaGenerateSecret> {
  const response = await axios.get<MfaGenerateSecret>(`/v1/mfa/totp/generate`).catch((error) => {
    throw new Error("Error generating secret");
  });

  return {
    secret: response.data.secret,
    url: response.data.url,
    email: response.data.email,
  };
}

export async function totpRegister(secret: string, totp: string): Promise<void> {
  await axios.post<void>(`/v1/mfa/totp/register`, { secret, totp });
}

type TotpChallengeResponse = {
  token: string;
  policyCheckStep: boolean;
};

export async function totpChallenge(totp: string): Promise<TotpChallengeResponse> {
  const response = await axios.post<TotpChallengeResponse>(`/v1/mfa/totp/challenge`, { totp });
  return {
    token: response.data.token,
    policyCheckStep: response.data.policyCheckStep,
  };
}

export async function validateResetPasswordToken(resetPasswordToken: string): Promise<void> {
  await axiosWithoutOrg.get<void>(`/auth/change-password/${resetPasswordToken}`);
}

export async function changePasswordWithResetPasswordToken(
  resetPasswordToken: string,
  newPassword: string,
  newPasswordConfirmation: string
): Promise<void> {
  await axiosWithoutOrg.post<void>(`/auth/change-password/${resetPasswordToken}`, {
    newPassword,
    newPasswordConfirmation,
  });
}

export type AuthResponse = {
  token?: string;
  partialLoginToken?: string;
  policyCheckStep: boolean;
  policyCheck?: {
    metadata: {
      provider: "totp";
    };
    policy?: "mfa_challenge" | "mfa_required";
  };
};

export async function passwordLogin(data: { email: string; password: string }): Promise<AuthResponse> {
  const response = await axiosWithoutOrg.post<AuthResponse>("/auth/login", data).catch((error) => {
    if (error.response && error.response.status === 401) {
      throw new Error(LoginServerSideErrors.EMAIL_PASSWORD);
    } else if (error.request) {
      throw new Error(LoginServerSideErrors.NETWORK);
    }
    throw new Error(LoginServerSideErrors.GENERIC);
  });

  return response.data;
}

export async function googleLogin(credentials: CredentialResponse): Promise<AuthResponse> {
  const response = await axiosWithoutOrg.post<AuthResponse>("/auth/login/google", credentials).catch((error) => {
    if (error.response && error.response.status === 401) {
      throw new Error(LoginServerSideErrors.EMAIL_PASSWORD);
    } else if (error.request) {
      throw new Error(LoginServerSideErrors.NETWORK);
    }
    throw new Error(LoginServerSideErrors.GENERIC);
  });

  return response.data;
}

export async function microsoftLogin(data: { uniqueId: string; accessToken: string }): Promise<AuthResponse> {
  const response = await axiosWithoutOrg.post<AuthResponse>("/auth/login/microsoft", data).catch((error) => {
    if (error.response && error.response.status === 401) {
      throw new Error(LoginServerSideErrors.EMAIL_PASSWORD);
    } else if (error.request) {
      throw new Error(LoginServerSideErrors.NETWORK);
    }
    throw new Error(LoginServerSideErrors.GENERIC);
  });
  return response.data;
}

export async function searchRoles({ page = 1, pageSize = 10 }: { page?: number; pageSize?: number } = {}): Promise<
  ListResult<Role>
> {
  const response = await dashboardAxios.get<ListResult<Role>>("/v1/roles", { params: { page, pageSize } });
  return response.data;
}
