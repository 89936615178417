import { BarOptions } from "@/modules/board/components/visualizations/BarVisualization";
import { CounterOptions } from "@/modules/board/components/visualizations/CounterVisualization";
import { LineOptions } from "@/modules/board/components/visualizations/LineVisualization";
import { MoodDisplayOptions } from "@/modules/board/components/visualizations/MoodDisplayVisualization";
import { PivotTableOptions } from "@/modules/board/components/visualizations/PivotTableVisualization";
import { TableOptions } from "@/modules/board/components/visualizations/TableVisualization";
import { User } from "@/types/user";
import { OrderDirection } from "@alanszp/core";

export enum MetricCategory {
  ENPS = "eNPS",
  LARA_SCORE = "laraScore",
}

export const ReportFieldTypeEnum = {
  STRING: "string",
  NUMBER: "number",
  BOOLEAN: "boolean",
  DATE: "date",
} as const;

export enum WidgetMetric {
  ENPS = "enpsScore",
  ENPS_ANSWERS = "enpsAnswers",
  QUESTIONS = "questions",
  RESPONSE_RATE = "responseRate",
  CASES = "cases",
  MOOD = "mood",
  MOOD_ANSWERS = "moodAnswers",
  DRIVER = "driverScore",
  DRIVER_ANSWERS = "driverAnswers",
  LARA_SCORE = "laraScore",
  CHATS = "chats",
  EMPLOYEES = "employees",
  ONBOARDING_AND_OFFBOARDING = "onboardingAndOffboarding",
  INDIVIDUAL_RESPONSES = "individualResponses",
  METRIC_HELPDESK_CONVERSATIONS = "helpdeskConversations",
  METRIC_HELPDESK_USED_ITEMS = "helpdeskUsedItems",
}

export enum VisualizationType {
  COUNTER = "counter",
  TABLE = "table",
  PIVOT_TABLE = "pivotTable",
  LINE = "line",
  BAR = "bar",
  MOOD_DISPLAY = "moodDisplay",
}

export const VisualizationTypeValues = Object.values(VisualizationType);

export enum TimeSegmentation {
  MONTH = "month",
}

export enum DateFieldReference {
  ANSWERED_AT = "answered_at",
  CHAT_CREATED_AT = "chat_created_at",
  DETECTED_AT = "detected_at",
  OPENED_AT = "opened_at",
  CLOSED_AT = "closed_at",
}

export const isDateFieldReference = (value: unknown): value is DateFieldReference => {
  return Object.values(DateFieldReference).includes(value as DateFieldReference);
};

////////////////////////////////
// Visualization
////////////////////////////////

export interface BaseOptions {
  /**
   * Whether to show a selector to change dynamically the group by option.
   * @default false
   *
   * If multiple group bys are defined in a widget, it will only affect the last one.
   *
   * Setting this to true, but giving values that are not valid as groupBy or not
   * defining a groupBy on widget, will not show the selector.
   */
  showGroupBySelector?: boolean;
}

interface CounterVisualizationOptions extends CounterOptions, BaseOptions {
  labelField: string;
}

interface TableVisualizationOptions extends TableOptions, BaseOptions {
  fields: string[];
  pivot?: {
    /**
     * The field to use as the label for the pivot
     * Generally, this is the field that is being grouped by
     */
    labelField: string;
    /**
     * The field to use as the value for the pivot
     * Generally, this is the field that is being aggregated
     */
    valueField: string;
    /**
     * Default to labelField if not specified
     */
    orderBy?: string;
    /**
     * Sorts the columns in the order specified by the order of the array based on the orderBy field
     */
    columnsOrder?: string[];
  };
}

export interface AxisOptions {
  labelField: string;
  valueField: string;
}

export interface SeriesOptions {
  /**
   * Whether to reverse the order of the series
   * @default true
   * Rows are reversed to display the most recent data first.
   * In some cases, it makes sense to be able to control this.
   */
  reverseSeries?: boolean;
}

interface LineVisualizationOptions extends LineOptions, SeriesOptions, BaseOptions {
  xAxis: AxisOptions;
  yAxis: AxisOptions;
}

interface BarVisualizationOptions extends BarOptions, SeriesOptions, BaseOptions {
  xAxis: AxisOptions;
  yAxis: AxisOptions;
}

interface MoodDisplayVisualizationOptions extends MoodDisplayOptions, BaseOptions {
  labelField: string;
}

/**
 * All the visualization props that are common to all visualizations
 */
export interface BaseVisualizationProps {
  widget: MetricWidget;
}

export interface CounterVisualization {
  type: VisualizationType.COUNTER;
  options: CounterVisualizationOptions;
}

export interface TableVisualization {
  type: VisualizationType.TABLE;
  options: TableVisualizationOptions;
}

export interface LineVisualization {
  type: VisualizationType.LINE;
  options: LineVisualizationOptions;
}

export interface BarVisualization {
  type: VisualizationType.BAR;
  options: BarVisualizationOptions;
}

export interface MoodDisplayVisualization {
  type: VisualizationType.MOOD_DISPLAY;
  options: MoodDisplayVisualizationOptions;
}

export interface PivotTableVisualization {
  type: VisualizationType.PIVOT_TABLE;
  options: PivotTableOptions;
}

export type Visualization =
  | CounterVisualization
  | TableVisualization
  | LineVisualization
  | BarVisualization
  | MoodDisplayVisualization
  | PivotTableVisualization;

export type WidgetLayout = { x: number; y: number; h: number; w: number };

export interface BaseWidget {
  id: string;
  layout: WidgetLayout;
}

export interface ExternalLinkClickAction {
  type: "link";
  /**
   * @default "_self"
   */
  target?: "_blank" | "_self";
  url: string;
  queryParams?: { key: string; value: string }[];
  // To be implemented in the future
  // injectWidgetFilters?: boolean | string[]; // Boolean to inject all filters, or array of filter names to inject
  // injectGlobalFilters?: boolean | string[]; // Boolean to inject all filters, or array of filter names to inject
}

export interface SetGlobalVariableClickAction {
  type: "setGlobalVariable";
  name: string;
  value: string;
}

export interface DebugClickAction {
  type: "debug";
}

/**
 * The action to perform when clicking on {@link ClickableOptions} visualizations
 */
export type ClickAction = ExternalLinkClickAction | SetGlobalVariableClickAction | DebugClickAction;

export interface FrontOrderBy {
  key: string;
  direction: OrderDirection;
  asNumber?: boolean;
  nullsFirst?: boolean;
}

export interface MetricWidget extends BaseWidget {
  type: "metric";
  name: string;
  tooltip?: string;
  visualization: Visualization;
  metric: WidgetMetric;
  timeSegmentation?: TimeSegmentation;
  hideDate?: boolean;
  filters?: Record<string, string | number | boolean>;
  groupBy?: string[];
  orderBy?: Record<string, OrderDirection>;
  frontOrderBy?: FrontOrderBy[];
  breakdown?: string;
  widgetBuilder?: boolean;
}

export interface TextWidget extends BaseWidget {
  type: "text";
  text: string;
  description?: string;
  options: {
    align?: "left" | "center" | "right";
  };
}

export interface MarkdownWidget extends BaseWidget {
  type: "markdown";
  content: string;
}

export type Widget = MetricWidget | TextWidget | MarkdownWidget;

export function isMetricWidget(widget: Widget): widget is MetricWidget {
  return widget.type === "metric";
}

export function isTextWidget(widget: Widget): widget is TextWidget {
  return widget.type === "text";
}

export function isMarkdownWidget(widget: Widget): widget is MarkdownWidget {
  return widget.type === "markdown";
}

export type WidgetOverrides = {
  [K in keyof MetricWidget]?: MetricWidget[K] | null;
};

export const DATE_FIELD = "date";

export const COMPARATIVE_FIELD = "comparative";

export enum AccessScope {
  ORGANIZATION = "organization",
  RESTRICTED = "restricted",
}

export interface GlobalVariableDefinition {
  name: string;
  defaultValue: string;
  queryParamAsDefault?: string;
}

export interface Board {
  id: string;
  name: string;
  description: string;
  creatorUserReference: string;
  updatedAt: string;
  lastUpdatedBy: string;
  hash: string;
  widgets: Widget[];
  accessScope: AccessScope;
  sharingList: string[];
  sharedRoleReferences: string[];
  globalVariables: GlobalVariableDefinition[];
  templateId: string | null;
}

export const BOARD_ACCESS_SCOPES = Object.values(AccessScope);

export interface BoardWithUser extends Board {
  creatorUser: User | null;
}

export const isBarVisualization = (visualization: Visualization): visualization is BarVisualization => {
  return visualization.type === VisualizationType.BAR;
};

export interface Template {
  id: string;
  name: string;
  description: string;
  creatorUserReference: string;
  lastUpdatedBy: string;
  widgets: Widget[];
  hash: string;
  globalVariables: GlobalVariableDefinition[];
  defaultAccessScope: AccessScope;
  updatedAt: string;
  createdAt: string;
  deletedAt: string | null;
}
