import { useIsTextOverflowing } from "@/hooks/useIsTextOverflowing";
import { Box, Tooltip } from "@mui/material";
import { ComponentProps, FC } from "react";

export const TooltipOnOverflow: FC<{
  title: ComponentProps<typeof Tooltip>["title"];
  tooltipProps?: Exclude<ComponentProps<typeof Tooltip>, "title">;
  boxProps?: ComponentProps<typeof Box>;
}> = ({ title, tooltipProps, boxProps }) => {
  const { ref: subtitleRef, isTextOverflowing } = useIsTextOverflowing();
  return (
    <Tooltip title={isTextOverflowing ? title : ""} followCursor {...tooltipProps}>
      <Box
        ref={subtitleRef}
        {...boxProps}
        sx={{
          borderBottom: isTextOverflowing ? "1px dashed" : "none",
          borderColor: (theme) => theme.palette.divider,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          ...boxProps?.sx,
        }}
      >
        {title}
      </Box>
    </Tooltip>
  );
};
