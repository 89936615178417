import { AsyncFunctionOrValue } from "@/components/FunctionOrValue";
import { SelectOption } from "@/components/SelectInput";
import { UseAdvancedFiltersState } from "@/components/advanced-filters/useAdvancedFilters";
import { EmployeeFieldValues, getEmployeeFieldValues } from "@/services/employeeService";
import { OldRoleCode } from "@/types/user";
import { DateRange } from "@mui/lab";
import { sub } from "date-fns";
import { FilterValues } from "./useAdvancedFilters";
import { countryLocale } from "@/utils/localizeCountry";
import { TFunction } from "i18next";
import { LeavingReasonType, TenureGroup } from "@/types/common";
import { leavingReasonTypeLocale, tenureGroupLocale } from "@/utils/localizeConstants";
import { EmployeeFieldTranslationGetter } from "@/hooks/useEmployeeFields";

export enum FilterType {
  DATE = "date",
  NUMBER = "number",
  SELECT = "select",
  STRING = "string",
  BOOLEAN = "boolean",
  EMPLOYEE = "employee",
  DRIVER = "driver",
  TEAM = "team",
}

export enum FilterOperatorType {
  EQUALS = "eq",
  NOT_EQUALS = "notEq",
  IN = "in",
  NOT_IN = "notIn",
  GREATER_THAN = "gt",
  GREATER_THAN_OR_EQUAL = "gte",
  LESS_THAN = "lt",
  LESS_THAN_OR_EQUAL = "lte",
  BETWEEN = "between",
  LIKE = "like",
  AFTER = "after",
  BEFORE = "before",
}

export enum FilterBoolean {
  TRUE = "true",
  FALSE = "false",
}

interface BaseFilterConfiguration {
  /**
   * The name of the filter, used to identify the filter in the query string
   */
  name: string;
  /**
   * The label of the filter, used to display the filter in the UI
   */
  label: string | React.ReactElement;
  /**
   * The type of the filter, used to determine the UI component to use
   */
  type: FilterType;
  /**
   * Operators available, used to determine the UI component to use
   */
  operators: FilterOperatorType[];

  // Maybe type it to be only the operators that are available for the type
  defaultOperator?: FilterOperatorType;
  /**
   * If set to true, the filter will be required and therefore not removable
   */
  required?: boolean;
  /**
   * If set to true, the filter input will be disabled and cannot be modified.
   */
  disabled?: boolean;
  /**
   * Function to override the content of the chip when the filter is selected
   */
  overrideActiveFilterLabel?: (
    filter: BaseFilterConfiguration,
    operator: FilterOperatorType,
    value: unknown
  ) => string | JSX.Element;
  /**
   * Function to override the component shown when the filter is selected in
   * the active filters list. If not set, the default chip component will be used.
   */
  overrideActiveFilterComponent?: (
    state: UseAdvancedFiltersState,
    filter: BaseFilterConfiguration,
    operator: FilterOperatorType,
    value: unknown
  ) => JSX.Element;
}

export interface DateFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.DATE;
  defaultValue?: Date | DateRange<Date>; // Can be more specific with another union type
  operators: (
    | FilterOperatorType.EQUALS
    | FilterOperatorType.IN
    | FilterOperatorType.BETWEEN
    | FilterOperatorType.BEFORE
    | FilterOperatorType.AFTER
  )[];
}

export interface SelectFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.SELECT;
  defaultValue?: (values: () => Promise<SelectOption[]>) => Promise<SelectOption>;
  operators: (
    | FilterOperatorType.EQUALS
    | FilterOperatorType.NOT_EQUALS
    | FilterOperatorType.IN
    | FilterOperatorType.NOT_IN
  )[];
  values: AsyncFunctionOrValue<SelectOption[], FilterValues>;
}

export interface StringFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.STRING;
  defaultValue?: string;
  operators: (
    | FilterOperatorType.EQUALS
    | FilterOperatorType.NOT_EQUALS
    | FilterOperatorType.IN
    | FilterOperatorType.NOT_IN
    | FilterOperatorType.LIKE
  )[];
}

export interface BooleanFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.BOOLEAN;
  defaultValue?: boolean;
  operators: FilterOperatorType.EQUALS[];
}

export interface NumberFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.NUMBER;
  defaultValue?: number;
  operators: (
    | FilterOperatorType.EQUALS
    | FilterOperatorType.NOT_EQUALS
    | FilterOperatorType.GREATER_THAN
    | FilterOperatorType.GREATER_THAN_OR_EQUAL
    | FilterOperatorType.LESS_THAN
    | FilterOperatorType.LESS_THAN_OR_EQUAL
  )[];
}

export interface EmployeeFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.EMPLOYEE;
  /**
   * Employee ID
   */
  defaultValue?: string;
  operators: (FilterOperatorType.EQUALS | FilterOperatorType.NOT_EQUALS)[];
  /**
   * If set, only employees with one of the given roles will be selectable
   * If not set, all employees will be selectable
   */
  roles?: OldRoleCode[];
}

export interface DriverFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.DRIVER;
  defaultValue?: (values: () => Promise<SelectOption[]>) => Promise<SelectOption>;
  operators: (
    | FilterOperatorType.EQUALS
    | FilterOperatorType.NOT_EQUALS
    | FilterOperatorType.IN
    | FilterOperatorType.NOT_IN
  )[];
  mode: "driver" | "subDriver";
  driverCode?: string;
}

export interface TeamFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.TEAM;
  defaultValue?: string;
  operators: FilterOperatorType.EQUALS[];
}

export type FilterConfiguration =
  | DateFilterConfiguration
  | SelectFilterConfiguration
  | StringFilterConfiguration
  | NumberFilterConfiguration
  | BooleanFilterConfiguration
  | EmployeeFilterConfiguration
  | DriverFilterConfiguration
  | TeamFilterConfiguration;

type OnlyRequired<T, V extends keyof T> = Pick<T, V> & Partial<T>;

export function createDateFilterConfiguration({
  name,
  label,
  ...override
}: OnlyRequired<DateFilterConfiguration, "name" | "label">): DateFilterConfiguration {
  const today = new Date();
  return {
    name,
    label,
    type: FilterType.DATE,
    operators: [
      FilterOperatorType.EQUALS,
      FilterOperatorType.AFTER,
      FilterOperatorType.BEFORE,
      FilterOperatorType.BETWEEN,
    ],
    defaultOperator: FilterOperatorType.BETWEEN,
    defaultValue: [sub(today, { months: 6 }), today],
    required: true,
    ...override,
  };
}

export function createBooleanFilterConfiguration({
  name,
  label,
  ...override
}: OnlyRequired<BooleanFilterConfiguration, "name" | "label">): BooleanFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.BOOLEAN,
    operators: [FilterOperatorType.EQUALS],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createStringFilterConfiguration({
  name,
  label,
  ...override
}: OnlyRequired<StringFilterConfiguration, "name" | "label">): StringFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.STRING,
    operators: [
      FilterOperatorType.EQUALS,
      FilterOperatorType.NOT_EQUALS,
      FilterOperatorType.IN,
      FilterOperatorType.NOT_IN,
      FilterOperatorType.LIKE,
    ],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createNumericFilterConfiguration({
  name,
  label,
  ...override
}: OnlyRequired<NumberFilterConfiguration, "name" | "label">): NumberFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.NUMBER,
    operators: [
      FilterOperatorType.EQUALS,
      FilterOperatorType.NOT_EQUALS,
      FilterOperatorType.GREATER_THAN,
      FilterOperatorType.GREATER_THAN_OR_EQUAL,
      FilterOperatorType.LESS_THAN,
      FilterOperatorType.LESS_THAN_OR_EQUAL,
    ],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createSelectFilterConfiguration({
  name,
  label,
  values,
  ...override
}: OnlyRequired<SelectFilterConfiguration, "name" | "label" | "values">): SelectFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.SELECT,
    values,
    operators: [
      FilterOperatorType.EQUALS,
      FilterOperatorType.NOT_EQUALS,
      FilterOperatorType.IN,
      FilterOperatorType.NOT_IN,
    ],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createEmployeeFilterConfiguration({
  name,
  label,
  ...override
}: OnlyRequired<EmployeeFilterConfiguration, "name" | "label">): EmployeeFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.EMPLOYEE,
    operators: [FilterOperatorType.EQUALS, FilterOperatorType.NOT_EQUALS],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createDriverFilterConfiguration({
  name,
  label,
  ...override
}: OnlyRequired<DriverFilterConfiguration, "name" | "label">): DriverFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.DRIVER,
    mode: "driver",
    operators: [
      FilterOperatorType.EQUALS,
      FilterOperatorType.NOT_EQUALS,
      FilterOperatorType.IN,
      FilterOperatorType.NOT_IN,
    ],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createSubDriverFilterConfiguration({
  name,
  label,
  driverCode,
  ...override
}: OnlyRequired<DriverFilterConfiguration, "name" | "label" | "driverCode">): DriverFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.DRIVER,
    mode: "subDriver",
    driverCode,
    operators: [FilterOperatorType.EQUALS, FilterOperatorType.NOT_EQUALS],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createSelectFilterForEmployeeField(
  field: string,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter,
  locale: string,
  fallbackTranslation: string
) {
  return createSelectFilterConfiguration({
    name: field,
    label: getEmployeeFieldTranslation(field, fallbackTranslation),
    values: (filters) =>
      getEmployeeFieldValues(field, filters)
        .then((response: EmployeeFieldValues) => {
          return response.fieldValues.map((fieldValue) => ({
            label: response.fieldName === "country" ? countryLocale(locale, fieldValue) : fieldValue,
            value: fieldValue,
          }));
        })
        .catch((error) => {
          console.error("errorGettingEmployeeFieldValue", error);
          return [];
        }),
  });
}

export function createTeamFilterConfiguration({
  name,
  label,
  ...override
}: OnlyRequired<TeamFilterConfiguration, "name" | "label">): TeamFilterConfiguration {
  return {
    name,
    label,
    type: FilterType.TEAM,
    operators: [FilterOperatorType.EQUALS],
    defaultOperator: FilterOperatorType.EQUALS,
    ...override,
  };
}

export function createLeavingReasonTypeFilterConfiguration(
  t: TFunction,
  getEmployeeFieldTranslation: EmployeeFieldTranslationGetter
) {
  return createSelectFilterConfiguration({
    name: "leavingReasonType",
    label: getEmployeeFieldTranslation("leavingReasonType", t("reporting.list.filters.leavingReasonType")),
    values: Object.values(LeavingReasonType).map((value) => ({ value, label: leavingReasonTypeLocale(t, value) })),
  });
}

export function createTenureFilterConfiguration(t: TFunction) {
  return createSelectFilterConfiguration({
    name: "tenure",
    label: t("reporting.list.filters.tenure"),
    values: Object.values(TenureGroup).map((value) => ({ value, label: tenureGroupLocale(t, value) })),
  });
}

export function createHelpdeskFeedbackFilterConfiguration(t: TFunction) {
  return createSelectFilterConfiguration({
    name: "feedback",
    label: t("board.detail.widget.filters.helpdeskFeedback.label"),
    values: [
      { value: "useful", label: t("board.detail.widget.filters.helpdeskFeedback.options.useful") },
      {
        value: "not_useful",
        label: t("board.detail.widget.filters.helpdeskFeedback.options.not_useful"),
      },
      {
        value: "incorrect",
        label: t("board.detail.widget.filters.helpdeskFeedback.options.incorrect"),
      },
    ],
  });
}
